<template>
    <div class="Login">
        <div class="startPage">
            <div class="logoBox">
                <i class="iconfont icon-logo"></i>
                <p class="slogan">管理客户从最重要的事开始</p>
            </div>
            <transition name="van-fade">
                <div v-if="showLogin" class="loginBox">
                    <ul class="formBox">
                        <li class="inputBox ">
                            <div class="iconfont icon-man"></div>
                            <input type="text" v-model="username" placeholder="输入孚盟MX账号" @keyup.enter="toLogin">
                        </li>
                        <li class="inputBox">
                            <div class="iconfont icon-password"></div>
                            <input v-model="password" type="password" placeholder="请输入密码" @keyup.enter="toLogin">
                        </li>
                    </ul>
                    <div class="btnBox">
                        <van-button class="bindBtn" size="large" @click="toLogin()">登录</van-button>
                    </div>
                </div>
            </transition>
            <transition name="van-fade">
                <div v-if="!showLogin&&companies.length>0" class="companyBox">
                    <p class="title">请选择你的企业</p>
                    <ul class="companyList">
                        <li v-for="(item,index) in  companies" @click="selCompany(item)" :class="{'van-hairline--bottom':index!=companies.length-1}" :key="index">
                            <span class="text">{{item.corpName}}</span>
                            <i class="iconfont icon-page-right"></i>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import titleMixin from '@/mixin/title'
import { mapMutations, mapActions } from 'vuex'
import hybrid from '@/libs/hybrid'
import { getIsApp } from '@/libs/utils'
export default {
    name: 'Login',
    title: ' ',
    mixins: [titleMixin],
    data() {
        return {
            showLogin: true,
            username: '',
            password: '',
            authInfo: {},
            companies: [],
            company: {}
        }
    },
    mounted() {
        this.Global.utils.rightMenu.clearMenu()
    },
    destroyed() {
        if (getIsApp()) {
            console.log('login module destorying')
            hybrid.mxInteraction({ action: 'webMounted' })
        }
    },
    methods: {
        ...mapMutations({
            set_userInfo: 'SET_USERINFO',
            set_companie: 'SET_COMPANIE'
        }),
        ...mapActions([
            'setContactList',
            'setGreyFunctions'
        ]),
        statusVerify(statusCode) {
            let flag = false
            let msg = ''
            switch (statusCode) {
                case -7:
                    msg = '您的账号未授权，请联系管理员'
                    break
                case -6:
                    msg = '请到pc端开通支付后使用'
                    break
                case -5:
                    msg = '请到pc端开通支付后使用'
                    break
                case -4:
                    msg = '您没有角色，请联系管理员分配'
                    break
                case -3:
                    msg = '请到pc端验证手机号'
                    break
                case -2:
                    msg = '你的IP被限制登录此系统'
                    break
                case -1:
                    msg = '已禁用，不能登录此企业'
                    break
                case 0:
                    msg = '请到pc端修改密码后使用'
                    break
                case 1:
                    flag = true
                    break
            }
            if (flag) {
                return true
            } else {
                this.$dialog.alert({ message: msg })
                return false
            }
        },

        selCompany(item) {
            this.company = item
            let flag = this.statusVerify(item.status)
            if (!flag) {
                return false
            }
            console.log('item', item)
            this.Global.utils.setItem('companie', JSON.stringify(item))
            this.Global.utils.setItem('username', this.username)
            this.set_companie(item)
            let { accessToken } = this.company
            this.Global.utils.setToken({ accessToken: accessToken.value })

            item.cId && this.setGreyFunctions({ cId: item.cId })
            this.setContactList()

            this.$router.replace({ name: 'Workbench' })
        },
        async toLogin() {
            try {
                if (this.username.trim() === '') {
                    this.username = ''
                    this.$dialog.alert({ message: '账号不得为空' })
                    return
                }
                if (this.password.trim() === '') {
                    this.password = ''
                    this.$dialog.alert({ message: '密码不得为空' })
                    return
                }

                this.$toast.loading({
                    duration: 0,
                    forbidClick: true,
                    loadingType: 'spinner',
                    message: '加载中...'
                })

                let loginInfo = await this.login()
                if (!loginInfo.flag) {
                    this.$toast.clear()
                    this.$toast.fail({ message: loginInfo.msg })
                    return
                }
                let individualAccessToken = loginInfo.data.accessToken.value
                this.Global.utils.setToken({ individualAccessToken: individualAccessToken })
                let { companies } = loginInfo.data
                // 只有一家企业时，直接去输入授权码
                if (companies.length == 1) {
                    this.selCompany(companies[0])
                } else {
                    this.companies = companies
                    this.showLogin = false
                }
                this.$toast.clear()
            } catch (error) {
                this.$toast.clear()
                alert(error)
            }
        },

        /* 登录 */
        async login() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.auth
                let data = {
                    user: this.username,
                    pass: this.password,
                    refer: 'logon',
                    platform: 'h5',
                    language: 'zh-cn'
                }
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    return { flag: true, data: res.data.data }
                } else {
                    return { flag: false, msg: res.data.msg }
                }
            } catch (error) {
                throw error
            }
        }
    },
    components: {}
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
